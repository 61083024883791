import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page lxn25ocsw1b-editor_css' },
  logo: {
    className: 'header3-logo',
    children:
      '/static/img/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#/company',
          children: [
            {
              children: (
                <span>
                  <p>公司简介</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#/detail',
          children: [
            {
              children: (
                <span>
                  <p>购买服务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
      },
      {
        name: 'elem3',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg3' },
      },
      {
        name: 'elem4',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg4' },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      '/static/img/class.jpg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>波动养生瑜伽训练营</p>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      0基础，轻松学，在家享受明星私教带练，全程有助教老师和班主任讲解答疑，不懂随时问老师，不怕学不会。<span
                      >
                        每天练习15分钟找回年轻状态。
                      </span>
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p></p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-pricing lxn2ppamdl6-editor_css',
      },
      {
        name: 'button',
        children: {
          href: '/#/detail',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>了解详情</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>服务内容</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>优质的服务是您线上瑜伽之旅的有力保障</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/appstore-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>丰富多样的课程设置</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        提供各种不同类型的瑜伽课程，如经络瑜伽、流瑜伽等，满足不同学员的需求和兴趣
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/read-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>专业教练授课</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    由经验丰富、资质认证的瑜伽教练进行线上教学，确保动作指导的准确性和安全性
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/compass-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>实时互动教学</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        学员可以在直播课程中与教练进行实时的互动，从而可以及时得到动作的指导和纠正
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/video-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>高清视频录制</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      除了直播，我们同时还准备了精心录制的高清课程视频，方便学员可以随时回看复习
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/funnelplot-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>课程分级</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    根据学员水平和经验，设置不同难度等级的课程，如初、中、高级
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/shopping-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>个性化定制课程</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          针对不同学员的特殊需求和身体状况，提供定制化的瑜伽练习方案
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/customerservice-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>冥想与放松课程</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    包含冥想引导、深度放松等课程，帮助学员减轻压力、舒缓身心
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/cloud-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>呼吸法教学</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>传授正确的呼吸技巧和方法，从而提升学员的练习效果</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lxn38hghqw',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/book-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>体式解析课程</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>详细讲解各个瑜伽体式的要点、功效和注意事项</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lxn38ifmc8g',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/signal-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>定期更新课程</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          不断推出新的课程内容和主题，保持学习的新鲜感和吸引力
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lxn38j569l7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/like-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>学习社群</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          建立学员社群，方便学员之间交流心得、互相鼓励和分享经验
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lxn38ju54ri',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:(
                <img src="/static/img/svg/detail-fill.svg" width="24" height="24" alt="img"></img>
              ),
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>健康评估与建议</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          为学员提供初始的健康评估，并根据评估结果给出合理的练习建议
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>售后服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    我们公司致力于为您提供卓越的线上瑜伽等兴趣服务类售后服务体验。
                  </p>
                  <p>
                    我们坚信，优质的售后服务是您线上瑜伽之旅的有力保障，让您能够全身心地投入到瑜伽的世界中，收获健康与美好。
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>课程咨询与指导</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  我们有专业的客服团队随时在线，为您解答关于线上瑜伽课程的任何疑问，包括课程内容、适合人群、练习要点等，帮助您选择最适合自己的课程。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>技术支持</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    确保您在使用线上平台时顺畅无阻。若遇到任何技术问题，如视频卡顿、连接故障等，我们的技术人员会迅速响应并解决，让您能安心享受瑜伽练习。
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>个性化反馈</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  您在练习过程中的感受和建议对我们至关重要。我们鼓励您反馈，无论是对课程难度、教练风格还是其他方面，我们都会认真对待并根据您的反馈进行优化和改进。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>社群互动</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  加入我们的线上瑜伽社群，与其他学员交流分享经验、心得，互相鼓励和支持。我们也会安排专业教练在社群中提供额外的指导和答疑。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>持续更新</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  我们不断更新线上课程内容，为您带来更多新颖、多样的瑜伽练习体验，让您始终保持学习的热情和动力。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>客户关怀</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  定期对您进行回访，了解您的练习进展和需求，为您提供贴心的关怀和建议，让您感受到我们始终与您相伴。
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Copyright©2024 波动积木 All Rights
        Reserved <a href="https://beian.miit.gov.cn/">京ICP备2024068304号-1</a> 客服电话：4008890162
      </span>
    ),
  },
};
